<template>
    <div class="checkout">
        <div class="card tw-flex">

            <div class="details ">
                <div class="summary tw-flex tw-w-full mr-25 ">
                    <v-col sm="2" lg="2" md="2" class="left">
                        <img :src="userSelectedTrip.fleetForThisTrip.vehicleImage" alt="" class="company-logo">
                    </v-col>
                    <!-- desktop -->
                    <v-col sm="6" lg='6' md="6" class="md:mr-15 mr-7 mt-3 ml-2 left">
                        <div class="line1 tw-flex justify-between">
                            <span class="route-joined"> {{userSelectedTrip.routeDepartureCity.substring(0,3)}} &nbsp; - &nbsp;
                                {{userSelectedTrip.routeDestinationCity.substring(0,3)}} </span>
                            <span class="seats"> Seats : {{getSeatsNumber}} </span>
                        </div>
                        <div class="line2 tw-hidden">
                            <span class="departure-time"> {{getTime(userSelectedTrip.takeOffDate,userSelectedTrip.takeOffTime)}} </span>
                            <span class="arrival-time ml-7"> {{getTime(userSelectedTrip.takeOffDate,userSelectedTrip.takeOffTime)}} </span>

                        </div>
                        <div class="footer mt-3 ">
                            <span class="bus-type"> {{userSelectedTrip.fleetForThisTrip.vehicleType}}</span>
                            <span class="mx-1 dot">.</span>
                            <span class="bus-class"> {{userSelectedTrip.fleetForThisTrip.vehicleClass}}</span>

                        </div>
                        <!-- mobile -->

                    </v-col>
                    <v-col md="6" sm="6" class="left mobile">
                        <img :src="userSelectedTrip.fleetForThisTrip.vehicleImage" alt="" class="company-logo mobile">
                        <span class="route-joined"> {{userSelectedTrip.routeDepartureCity.substring(0,3)}} &nbsp; - &nbsp;
                            {{userSelectedTrip.routeDestinationCity.substring(0,3)}} </span>
                        <span class="departure-time tw-hidden"> {{getTime(userSelectedTrip.takeOffDate,userSelectedTrip.takeOffTime)}} </span>
                    </v-col>
                    <v-divider vertical class="vertical-mobile"></v-divider>
                    <v-col lg="3" sm="3" md="3" class="right">
                        <div>
                            <span>Total Amount</span>
                            <p :style="{color : companyData.companyColor ? companyData.companyColor : ''}"> ₦{{formatNumber(oneWayTotalAmount)}} </p>
                        </div>

                    </v-col>
                    <v-col md="6" sm="6" class="right mobile">
                        <span class="seats mt-7"> Seats : {{getSeatsNumber}} </span>
                    </v-col>
                </div>
            </div>
            <!-- <div class="vertical"></div> -->

            <div class="cta tw-flex tw-items-center tw-justify-center">
                <button :style="{backgroundColor : companyData.companyColor ? companyData.companyColor : ''}"
                    class="continue" @click="routeNext"> Continue</button>
            </div>


        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import dayjs from "dayjs";

    export default {
        name: "OneWayCheckout",
        data: () => ({
            items: ['Lowest Price', 'Highest Price'],
        }),
      computed:{
        ...mapGetters("companyProfile", ["companyData"]),
        ...mapGetters("tripAndTravellerDetails", ["userSelectedTrip","tripAndTravellerDetails"]),
        ...mapGetters("seatsSelection", ["seats","oneWayTotalAmount",]),
        getSeatsNumber(){
          let numbers = ""
          this.seats.forEach(seat =>{
            if (!numbers.includes(seat.seatNumber)){
              numbers = numbers+ seat.seatNumber + " "
            }
          })
          return numbers
        }
      },
      methods:{
        formatNumber(number){
          if(number){
            return number.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
          }
        },
        routeNext(){
          sessionStorage.setItem('minusFromTimer',JSON.stringify(false))
            if(this.$route.name === 'OneWaySelectSeats' ){
              if (!this.seats.length){
                this.$displaySnackbar({
                  message : "Select a seat(s) to continue"
                })
              }
              else {
                this.$router.push({name: 'OneWayInputDetails'})

              }
            }
            else{

              this.tripAndTravellerDetails.seats.forEach(seat => {
                if (seat.travellerDetail && !Object.keys(seat.travellerDetail).length
                    && !seat.travellerDetail.firstName && !seat.travellerDetail.lastName
                    && !seat.travellerDetail.phoneNumber && !seat.travellerDetail.email
                    && !seat.travellerDetail.nextOfKinFirstName && !seat.travellerDetail.nextOfKinLastName
                    && !seat.travellerDetail.nextOfKinPhoneNumber){
                  this.$displaySnackbar({
                    message : "Please fill in Traveler details"
                  })
                }
                else {
                  this.$router.push({name:'OneWayCheckout'})
                }
              })

            }

        },
        getTime(date,time){
          return dayjs(date+time).format('LT')
        },

        }
    }
</script>

<style lang='scss' scoped>
    .big-circle1 {
        width: 100px;
        position: absolute;
        left: -50px;
        top: 50px;

    }

    .big-circle2 {
        width: 100px;
        position: absolute;
        right: -50px;
        top: 50px;


    }

    .left,
    .right,
    .company-logo {
        @media (max-width:768px) {
            display: none;
        }
    }

    .mobile {
        display: none;

        @media (max-width:768px) {
            display: flex;
            flex-direction: column;
        }
    }

    .col {
        padding: 0 !important;
    }

    .card {
        width: 100%;
        background-color: #F5F5F5;
        box-shadow: 4px -4px 40px rgba(17, 16, 16, 0.1);
        padding: 30px 50px 30px 150px;
        position: relative;

        @media (max-width:1264px) {
            padding: 30px 50px 30px 60px;
        }

        @media (max-width:1024px) {
            padding: 30px;
        }

        @media (max-width:768px) {
            padding: 15px;
        }
    }

    .details {
        border: none;
        border-right: 2px dashed rgba(79, 79, 79, 0.39);
        padding-right: 70px ;
        width: 70%;
        @media (max-width: 1024px) {
            padding-right: 40px;
        }
    }


    .summary {
        width: 100%;
        padding: 2rem 10rem;
        background-color: #F2F2F4;
        border: 1px dashed #000000;

        @media (max-width:1264px) {
            padding: 2rem 4rem;
        }

        @media (max-width:1024px) {
            padding: 1rem;
        }

        @media (max-width:576px) {
            padding: 0.5rem;
        }

    }

    .vertical-mobile {
        @media (max-width:768px) {
            margin: 0 10px;
        }
    }

    .company-logo {
        width: 60px;
        height: 60px;
        border-radius: 50%;

        @media (max-width:768px) {
            width: 30px;
            height: 30px;
        }
    }

    .vertical {
        border: none;
        border-left: 2px dashed rgba(79, 79, 79, 0.39);
        margin-left: 60px;
        font-size: 20px;

        @media (max-width:1024px) {
            margin-left: 30px;
        }

        @media (max-width:576px) {
            display: none;
        }
    }

    .line1 {
        justify-content: space-between;
    }

    .route-joined {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 17px;
        color: #4F4F4F;

        @media (max-width:768px) {
            font-size: 9px;
            margin: 5px 0;
        }
    }

    .arrival-time,
    .departure-time {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 9px;
        line-height: 10px;
        color: #4F4F4F;
    }

    .seats {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 19px;
        line-height: 22px;
        color: #4F4F4F;
    }

    .footer {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 9px;
        line-height: 10px;
        color: rgba(79, 79, 79, 0.5);
    }

    .right {
        margin-left: 50px;

        @media (max-width:1024px) {
            margin-left: 20px;
        }
    }

    .right span {
        font-family: 'Inter';
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #4F4F4F;
    }

    .right p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        color: #004AAD;

    }

    .cta {
        width: 30%;
    }

    .continue {
        width: 186px;
        height: 41px;
        background: #004AAD;
        border-radius: 4px;
        font-family: 'Inter';
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #FAFAFA;

        @media (max-width:768px) {
            width: 100px;
            font-size: 10px;
        }

        @media (max-width:576px) {
            margin-left: 15px;
        }
    }
</style>